.grid {
  max-width: 1200px !important;
  margin-right: auto;
  margin-left: auto;
}

.grid-player {
  max-width: 400px;
}

.grid-600 {
  max-width: 600px !important;
}

.grid-full {
  max-width: 1200px !important;
}

.slider-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  --progress-bar-height: 4px;
  --thumb-h: 10px;
  --thumb-w: 10px;
}

.range {
  -webkit-appearance: none;
  background-color: #51e1ed;
  height: var(--progress-bar-height);
  width: 100%;
  cursor: pointer;
  opacity: 1;
  margin: 0 auto;
  border-radius: 10px;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: var(--thumb-w);
  height: var(--thumb-h);

  background-color: #207398;
  border-radius: 50%;
  cursor: pointer;
}

.progress-bar-cover {
  background-color: #207398;
  width: 20%;
  height: var(--progress-bar-height);
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 63%;
  transform: translateY(-50%);
  z-index: 3;
  user-select: none;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.player-container {
  width: 100%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.modal {
  width: 400px;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
